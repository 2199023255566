import ctx from "../";
import dictionaries from "./dictionaries";
import org from "./org";
import services from "./services";
import servicesCategories from "./servicesCategories";
import products from "./products";
import persons from "./persons";
import programs from "./programs";
import contracts from "./contracts";
import insured from "./insured";
import diagnosis from "./diagnosis";
import doctors from "./doctors";
import prices from "./prices";
import operators from "./operators";

export default async () => {
  console.log("ctx.src: loaded");
  ctx.src = {};
  await Promise.all([
    dictionaries(),
    org(),
    services(),
    servicesCategories(),
    products(),
    persons(),
    programs(),
    // contracts(),
    // insured(),
    diagnosis(),
    doctors(),
    prices(),
    operators()
  ]);
  console.log("ctx.src: initialized");
};
